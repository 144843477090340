@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.viz-pos {
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: red;
}

.viz-start {
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: green;
}

.viz-text {
  position: absolute;
  top: 130%;
  left: 0;
  transform: translateX(-50%) translateY(-100%);
}
